/* VideoItem.scss */
.video-item {
  display: flex;
  flex-wrap: wrap;
  width: 40vw;
  height: 500px;
  position: relative;
  padding-bottom: 0%; /* Aspect ratio 16:9 */
  overflow: hidden;

  iframe {
    position: absolute;
    width: 100%;
    height: 50%;
    top: 0;
    left: 0;
  }
}
