@import "../../responsive/breakpoints";
.ss-trade_dashboard_resetpassword_page_wrapper {
  margin-top: 0px;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  height: 90vh;
  background-image: url("https://res.cloudinary.com/deavhufn6/image/upload/v1694425310/sst-bg_dchkjr.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 108px;
  @include breakpoints(largeMobile) {
    height: auto;
  }
  .ss-trade_dashboard_resetpassword_card {
    padding: 25px;
    min-height: 250px;
    width: 30%;
    margin: auto;
    background-color: rgb(1 21 54) !important;
    @include breakpoints(laptop) {
      width: 50%;
    }
    @include breakpoints(tablet) {
      width: 70%;
    }
    @include breakpoints(largeMobile) {
      width: 80%;
    }
    .ss-trade_section_title {
      text-align: center;
      margin-bottom: 20px;
      h2 {
        color: #4a4a69;
        font-size: 25px;
        @include breakpoints(tablet) {
          font-size: 20px;
        }
      }
    }
    .login_page_social_icon {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 20px 0;
      .social_icon {
        list-style-type: none;
        padding: 0 15px;
        font-size: 28px;
        color: rgb(155, 150, 150);
        cursor: pointer;
      }
      .facebook {
        &:hover {
          color: #1877f2;
        }
      }
      .twitter {
        &:hover {
          color: #25d366;
        }
      }
      .instagram {
        &:hover {
          color: #e4405f;
        }
      }
      .youtube {
        &:hover {
          color: #cd201f;
        }
      }
    }
    .hr_border {
      height: 2px;
      width: 100%;
      background-color: #7b5eeaa6;
      margin-bottom: 5px;
    }
    .ss-trade_dashboard_resetpassword_content {
      form {
        .form_group {
          margin: 15px 0;
          position: relative;
          .input_group {
            position: relative;
          }
          .right {
            margin-right: 19px;
          }
          label {
            color: $text-p-color;
            font-size: 15px;
            font-weight: 600;
            @include breakpoints(tablet) {
              font-size: 14px;
            }
          }
          .input_field {
            width: 100%;
            padding: 10px 8px;
            border: $border-3;
            outline: none;
            border-radius: 3px;
            background-color: rgba(123, 94, 234, 0.08);
            color: #9fa4a6;
            &:focus {
              background-color: rgba(123, 94, 234, 0.7);
              color: #fff;
              &::placeholder {
                color: #fff;
              }
            }
            &:active {
              background-color: rgba(123, 94, 234, 0.7);
              color: #fff;
            }
            &::placeholder {
              text-transform: uppercase;
              color: #9fa4a6;
              font-size: 11px;
            }
          }
        }
        .submit_btn {
          background-color: #00a5ec;
          padding: 10px 12px;
          width: 100%;
          border-radius: 3px;
          border: $hover-border;
          font-size: 15px;
          font-weight: 600;
          text-transform: capitalize;
          color: #fff;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          margin-top: 10px !important;
          &:hover {
            background-color: #0183bb;
          }
        }
        .go_to_register {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 15px;
          text-align: center;
          p {
            color: #000;
            font-size: 14px;
            font-weight: 600;
            padding: 0px 5px;
            a {
              color: #7a5eeaf8 !important;
            }
            .log_page_nav_link {
              &:hover {
                border-bottom: 2px solid #7b5eea;
                color: #7b5eea;
              }
            }
          }
          .login_nav_break_point {
            color: #7a5eeaf8 !important;
          }
        }
        .go_to_home {
          margin-top: 15px;
          text-align: center;
          p {
            font-size: 14px;
            font-weight: 600;
            a {
              color: #7a5eeaf8;
            }
          }
        }
      }
    }
  }
}
