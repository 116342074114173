@import "../../../abstract/variables";
@import "../../../responsive/breakpoints";
.tp_income_distribution_page_wrapper {
  margin-top: 10px;
  .tp_income_distribution_form_card {
    padding: 15px;
    min-height: 250px;
    .tp_section_title {
      text-align: start;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        font-size: 16px;
        text-align: left;
        color: $text-p-color;
        text-transform: uppercase;
        margin-bottom: 10px !important;
        position: relative;
        margin-left: 10px !important;
        font-weight: 500 !important;
        &::before {
          content: "";
          position: absolute;
          width: 3px;
          height: 90%;
          background: $text-menu-active-color;
          top: 1px;
          left: -8px;
          bottom: 0;
        }
        @include breakpoints(mdlgMobile) {
          font-size: 14px;
        }
      }
      .tp_section_title_right_side {
        display: flex;
        gap: 5px;
        @include breakpoints(miniPortraitTablet) {
          flex-direction: column;
          gap: 2px;
        }
        .tp_section_Trx_balance {
          p {
            @include breakpoints(mdlgMobile) {
              font-size: 14px;
            }
            white-space: nowrap;
            color: #248ed5 !important;
            background-color: rgba(26, 121, 156, 0.2) !important;
          }
        }
      }
    }
    .autopool_container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      grid-gap: 20px;
      padding-bottom: 20px;
      .autopool_controller {
        justify-self: center;
        padding: 22px 30px;
        background-color: var(--bg-white);
        transition: all 0.3s ease-in-out;
        box-shadow: var(--box-shadow-1);
        border-radius: 10px;
        .autopool_status {
          // margin-top: 40px;
          h4 {
            font-size: 16px;
            text-align: left;
            color: $text-p-color;
            text-transform: uppercase;
            margin-bottom: 10px !important;
            position: relative;
            margin-left: 10px !important;
            font-weight: 500 !important;
            &::before {
              content: "";
              position: absolute;
              width: 3px;
              height: 90%;
              background: $text-menu-active-color;
              top: 1px;
              left: -8px;
              bottom: 0;
            }
            @include breakpoints(mdlgMobile) {
              font-size: 14px;
            }
          }
          p {
            color: $text-black;
            font-size: 16px;
            .on {
              color: limeGreen;
            }
            .off {
              color: red;
            }
          }
        }
        .amount_type_container {
          display: flex;
          gap: 20px;
          .amount_type {
            .amount_type_label {
              color: $text-black !important;
              margin-right: 5px !important;
            }
            .switch {
              display: inline-block;
              width: 35px;
              height: 18px;
              margin: 4px;
              transform: translateY(50%);
              position: relative;
              input {
                display: none;
                &:checked + .slider {
                  box-shadow: 0 0 0 2px limeGreen, 0 0 8px limeGreen;
                  &:before {
                    transform: translateX(18px); /*translateX(w-h)*/
                    background-color: limeGreen;
                  }
                }
              }
              .slider {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                border-radius: 40px;
                box-shadow: 0 0 0 2px rgb(249, 9, 9), 0 0 4px rgb(246, 5, 5);
                cursor: pointer;
                border: 4px solid transparent;
                overflow: hidden;
                transition: 0.2s;
                &:before {
                  position: absolute;
                  content: "";
                  width: 100%;
                  height: 100%;
                  background-color: rgb(255, 0, 0);
                  border-radius: 40px;
                  transform: translateX(-18px); /*translateX(-(w-h))*/
                  transition: 0.2s;
                }
              }
            }
          }
        }
      }
    }
  }
}
.percentage_field {
  position: relative !important;
  &:after {
    content: "%" !important;
    width: 1em !important;
    height: 1em !important;
    position: absolute !important;
    top: 66% !important;
    right: 20px !important;
    transform: translateY(-50%) !important;
  }
  input {
    width: 100% !important;
    padding: 10px 8px !important;
    // padding-left: 23px !important;
    // padding-right: 150px !important;
  }
}

#inr_total {
  color: #248ed5 !important;
  background-color: rgba(26, 121, 156, 0.2) !important;
}
#to_token_total {
  color: #24d5b8 !important;
  background-color: rgba(26, 156, 134, 0.2) !important;
}
