@import "../../../abstract/variables";
@import "../../../responsive/breakpoints";
.preview_image {
  .img_preview {
    width: 300px !important;
    height: 300px;
    margin-bottom: 20px !important;
    @include breakpoints(portraitTablet) {
      width: 100% !important;
      height: 250px !important;
    }
    .popupimage {
      width: 100%;
      height: 100%;
    }
  }
}

.img_preview {
  width: 100% !important;
  height: 100%;
  margin-bottom: 20px !important;
  @include breakpoints(portraitTablet) {
    width: 100% !important;
    height: 250px !important;
  }
  .popupimage {
    width: 100%;
    height: 100%;
  }
}
