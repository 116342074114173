/* Big Card */
.big-card {
  width: 15rem; /* Adjust the size as needed */
  height: 15rem; /* Adjust the size as needed */
}

.big-card:hover {
  transform: scale(1.1);
}

/* Small Card */
.small-card {
  width: 12.625rem;
  height: 12.625rem;
}

.small-card:hover {
  transform: scale(1.05);
}
.img_container {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
  }
}
// for img focus

#borderimg {
  --b: 0.5em; /* border width */
  --c: 3em; /* corner size */
  --r: 2em; /* corner rounding */
  position: relative;
  margin: 1em auto;
  border: solid var(--b) transparent;
  padding: 1em;
  max-width: 23em;
  font: 1.25em ubuntu, sans-serif;

  &::before {
    position: absolute;
    z-index: -1;
    inset: calc(-1 * var(--b));
    border: inherit;
    border-radius: var(--r);
    background: linear-gradient(orange, deeppink, purple) border-box;
    --corner: conic-gradient(from -90deg at var(--c) var(--c), red 25%, #0000 0)
      0 0 / calc(100% - var(--c)) calc(100% - var(--c)) border-box;
    --inner: conic-gradient(red 0 0) padding-box;
    -webkit-mask: var(--corner), var(--inner);
    -webkit-mask-composite: source-out;
    mask: var(--corner) subtract, var(--inner);
    content: "";
  }
}

.button_container {
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    position: relative;
    display: inline-block;
    margin: 15px;
    padding: 15px 30px;
    text-align: center;
    font-size: 18px;
    letter-spacing: 1px;
    text-decoration: none;
    color: #f02603;
    background: transparent;
    cursor: pointer;
    transition: ease-out 0.5s;
    border: 2px solid #f02603;
    border-radius: 10px;
    box-shadow: inset 0 0 0 0 #f02603;
  }

  button:hover {
    color: white;
    box-shadow: inset 0 -100px 0 0 #f02603;
  }

  button:active {
    transform: scale(0.9);
  }
}
.button_container_video {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    position: relative;
    display: inline-block;
    margin: 15px;
    margin-top: 16rem;
    padding: 15px 30px;
    text-align: center;
    font-size: 18px;
    letter-spacing: 1px;
    text-decoration: none;
    color: #f02603;
    background: transparent;
    cursor: pointer;
    transition: ease-out 0.5s;
    border: 2px solid #f02603;
    border-radius: 10px;
    box-shadow: inset 0 0 0 0 #f02603;
  }

  button:hover {
    color: white;
    box-shadow: inset 0 -100px 0 0 #f02603;
  }

  button:active {
    transform: scale(0.9);
  }
}
// for delete confirm modal
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 22%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: absolute;
  background: #000000;
  z-index: 100;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;

  h2 {
    margin-bottom: 20px;
    color: #fff;
  }

  .button-container {
    button {
      margin: 0 10px;
      padding: 8px 16px;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &:first-child {
        background-color: #ff3b30;
        color: white;
      }

      &:last-child {
        background-color: #007aff;
        color: white;
      }
    }
  }
}
