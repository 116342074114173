.termAndConditions_body {
  background-color: #1e0b56 !important;
  // background: $bg-blue-linear-gradient;
  color: #fff;
  .termAndConditions_sub {
    padding-top: 40px;
    width: 80%;
    margin: auto;
    @include breakpoints (4kdevice){
      width: 100%;
    }
    @include breakpoints (portraitTablet){
      width: 100%;
    }
    @include breakpoints (mdlgMobile){
      padding-top: 30px;
    }
  }
}
.termAndConditions {
  padding-top: 40px;
  text-align: center;
}
.termAndConditionsWelcome {
  padding: 5px 0px;
  text-align: center;
}
.termAndConditionBasicDetails {
  h1 {
    padding-top: 40px;
    text-align: center;
  }
  h2 {
    padding-top: 40px;
    text-align: left;
  }
  h4 {
    text-align: center;
  }
  p {
    padding: 10px 0px;
  }
}

.termAndCondition_section_details {
  padding: 40px;
  h2 {
    padding-top: 40px;
    text-align: center;
  }
  .showAndList {
    padding: 10px;
    p {
      font-weight: 500;
    }
    li {
      padding: 2px 15px;
      list-style-type: none;
    }
  }
}

.showAndList {
  padding: 10px;
  p {
    font-weight: 500;
  }
  li {
    padding: 2px 10px;
    list-style-type: none;
  }
  h2{
    text-align: left !important;
  }
}
