@import "../../responsive/breakpoints";
* {
  box-sizing: border-box;
}
.sst__admin__landingPage__wrapper {
  background: linear-gradient(to bottom, transparent, rgb(255, 255, 255))
    rgb(214, 219, 220);
  .sst__admin__landingPage__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 6rem;
    min-height: 100vh;
    .sst__admin__landingPage__container {
      .sst__admin__login__form {
        background: #fff;
        padding: 20px;
        border: 1px solid #eee;
        width: 450px;
        max-width: 100%;
        border-radius: 5px;
        @include breakpoints(laptop) {
          width: 50%;
        }
        @include breakpoints(tablet) {
          width: 450px;
        }
        @include breakpoints(largeMobile) {
          width: 330px;
        }
        h2 {
          color: #000;
          font-size: 35px;
          text-align: center;
        }
      }
      form {
        padding-top: 20px;
        .sst__admin__form__group {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          gap: 10px;
          .form__group {
            width: 100%;
            position: relative;
            label {
              color: #000;
              font-weight: 600;
            }
            input {
              width: 100%;
              padding: 10px;
              border: 1px solid #ddd;
              outline: none;
              font-size: 15px;
            }
            button {
              padding: 10px 24px;
              background: $bg-primary;
              border: 1px solid #fff;
              border-radius: 3px;
              color: #fff;
              font-size: 15px;
              text-transform: uppercase;
              font-weight: 600;
              margin-top: 25px;
              cursor: pointer;
              transition: all 0.3s ease-in-out;
              &:hover {
                opacity: 0.8;
              }
            }
            .passwordShow {
              position: absolute;
              top: 52%;
              right: 3%;
              font-size: 20px;
              color: #000;
              cursor: pointer;
            }
            .captchaContainer {
              display: flex;
              align-items: center;
              margin-top: 15px;
              border: 1px solid #ddd;
              padding: 0 10px;
              .digits {
                display: flex;
                align-items: center;
                gap: 5px;
              }
              .otpCode {
                border: none;
              }
            }
          }
        }
      }
    }
  }
}
